<!-- 邀请注册-常见问题 -->
<template>
  <div class="conBox dip fdcolmun">
    <div class="headBox dip fdcolmun">
      <div class="headBoxH3">{{ $fanyi("你有什么问题吗?") }}</div>
      <div class="headBoxH2">{{ $fanyi("常见问题") }}</div>
      <div class="headBoxCon">
        {{
          $fanyi(
            "查看Rakumart进口常见问题，并发现所有的好处和保证在您的范围内。"
          )
        }}
      </div>
    </div>

    <div class="problemListBox">
      <foldingPanel>
        <template slot="title">
          <div>{{ $fanyi("什么是Rakumart？") }}</div>
        </template>

        <span class="problemItemConBx">{{
          $fanyi(
            "Rakumart是一个“一体化”的进口平台，将西班牙用户与数千家中国供应商连接起来，以具有竞争力的价格提供产品。我们管理整个过程：采购，订单，质量检查，运输和售后服务，客户服务100%用西班牙语。"
          )
        }}</span>
      </foldingPanel>

      <foldingPanel>
        <template slot="title">
          <div>{{ $fanyi("我们在哪里进口？") }}</div>
        </template>

        <span class="problemItemConBx">
          <div
            v-html="$fanyi('在Rakumart.es，我们只从中国进口到西班牙。')"
          ></div>
        </span>
      </foldingPanel>

      <foldingPanel>
        <template slot="title">
          <div>{{ $fanyi("我需要一个帐户才能下订单吗？") }}</div>
        </template>

        <span class="problemItemConBx">
          {{
            $fanyi(
              "是的，你需要一个帐户。在Rakumart.es免费注册，开始订购。有了您的帐户，您将能够访问有用的工具来管理和监控您的购买。此外，您的个人信息将被安全存储，使未来的订单更容易和快速。"
            )
          }}
        </span>
      </foldingPanel>

      <foldingPanel>
        <template slot="title">
          <div>{{ $fanyi("我们的服务是针对谁？") }}</div>
        </template>

        <span class="problemItemConBx">
          {{
            $fanyi(
              "Rakumart针对的是企业、公司和自由职业者，他们希望以具有竞争力的价格购买批发产品。我们提供个性化的关注，以满足独特的需求，并支持客户的成长和扩张。我们不提供零售服务。"
            )
          }}
        </span>
      </foldingPanel>

      <foldingPanel>
        <template slot="title">
          <div>{{ $fanyi("Rakumart有什么优势？") }}</div>
        </template>

        <span class="problemItemConBx">
          {{
            $fanyi(
              "Rakumart以具有竞争力的价格提供产品，在一次运输中从多个验证供应商购买，并优化进口成本。我们包括西班牙的物流，质量控制，海关管理和服务，所有这些都由我们在西班牙的保修支持。"
            )
          }}
        </span>
      </foldingPanel>

      <foldingPanel>
        <template slot="title">
          <div>{{ $fanyi("Rakumart提供什么保证？") }}</div>
        </template>

        <span class="problemItemConBx">
          {{
            $fanyi(
              "Rakumart提供从中国采购和进口的全程支持，包括质量控制、有缺陷产品的3年保修和运输问题的退款。我们还管理所有的进口和海关手续，使您的购买没有麻烦。"
            )
          }}
        </span>
      </foldingPanel>

      <foldingPanel>
        <template slot="title">
          <div>{{ $fanyi("我的产品是否通过了符合欧洲法规的认证？") }}</div>
        </template>

        <span class="problemItemConBx">
          {{
            $fanyi(
              "我们承诺进口到欧洲的所有产品都符合欧盟的质量和安全标准。我们检查是否符合欧盟法规，如果我们发现产品没有必要的认证，我们将寻找其他供应商并通知客户。"
            )
          }}
        </span>
      </foldingPanel>

      <foldingPanel>
        <template slot="title">
          <div>{{ $fanyi("你如何检查供应商？") }}</div>
        </template>

        <span class="problemItemConBx">
          {{
            $fanyi(
              "在Rakumart，我们通过严格的选择过程来保证我们供应商的质量和可靠性。我们评估产品、交货时间和沟通。凭借在中国、日本、韩国和巴西的经验，我们了解全球需求，并保持可靠供应商的最新数据库。此外，我们保持持续的沟通，以满足我们的质量期望。"
            )
          }}
        </span>
      </foldingPanel>
    </div>
  </div>
</template>
<script>
import foldingPanel from "./foldingPanel.vue";
export default {
  data() {
    return {};
  },
  components: {
    foldingPanel,
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.conBox {
  margin: 0 auto;
  padding: 115px 0;

  .headBox {
    text-align: center;
    margin: 0 auto 0;
    width: 920px;
    .headBoxH3 {
      color: var(--Rakumart-500, #1a73e8);
      font-size: 20px;
      font-weight: 700;
      line-height: 30px; /* 150% */
      margin-bottom: 8px;
    }
    .headBoxH2 {
      color: var(--Gray-950, #0c111d);
      font-size: 36px;
      font-weight: 600;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
      margin-bottom: 16px;
    }
    .headBoxCon {
      width: 830px;

      font-size: 18px;
      line-height: 28px; /* 155.556% */
      margin-bottom: 56px;
    }
  }
  .problemListBox {
    width: 1058px;
    .problemItemConBx {
      font-size: 18px;
      line-height: 28px; /* 155.556% */
      color: var(--Gray-600, #475467);
    }
  }
}
</style>
