<template>
  <div id="homePageCon">
    <homePageTop />
    <!-- 网站简介视频弹窗 -->
    <beginnerTipsDia ref="BeginnerTipsDia" />
    <!-- 商品搜索 -->
    <productSearch />
    <!-- 网站优势 -->
    <websiteAdvantages v-if="show" />
    <!-- 著名供应商 -->
    <famousSupplier :show="show" />
    <!-- 如何运作 -->
    <howToOperate v-if="show" />
    <!-- 著名产品 -->
    <famousProducts :show="show" />
    <!-- 进口好处 -->
    <importBenefits v-if="show" />
    <!-- 已注册用户 -->
    <registeredUser v-if="show" />
    <!-- 注册邀请 -->
    <registrationInvitation v-if="show" />
    <!-- 常见问题 -->
    <commonProblem v-if="show" />
    <Foot />
  </div>
</template>

<script>
import beginnerTipsDia from "../../components/homePage/beginnerTipsDia.vue";
import homePageTop from "../../components/head/HomePageTop.vue";
import productSearch from "../../components/homePage/productSearch.vue";
import famousSupplier from "../../components/homePage/famousSupplier.vue";
import famousProducts from "../../components/homePage/famousProducts.vue";
import registrationInvitation from "../../components/homePage/registrationInvitation.vue";
import websiteAdvantages from "../../components/homePage/websiteAdvantages.vue";
import howToOperate from "../../components/homePage/howToOperate.vue";
import importBenefits from "../../components/homePage/importBenefits.vue";
import registeredUser from "../../components/homePage/registeredUser.vue";
import commonProblem from "../../components/homePage/commonProblem.vue";
import Foot from "../../components/foot/Foot.vue";
export default {
  data() {
    return {
      show: !localStorage.getItem("user_token"),
    };
  },
  components: {
    homePageTop,
    Foot,
    beginnerTipsDia,
    productSearch,
    famousSupplier,
    famousProducts,
    registrationInvitation,
    websiteAdvantages,
    howToOperate,
    importBenefits,
    registeredUser,
    commonProblem,
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
  watch: {
    "$store.state.userInfo"(onew, old) {
      console.log(!!onew);
      if (!onew) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
#homePageCon {
  background-color: white;
  min-width: 1470px;
}
* {
  color: #344054;
}
</style>
