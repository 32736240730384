<template>
  <div :class="{ active: active }" class="iconBox dip">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21 21L15.0001 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
        :stroke="!active ? '#344054' : '#1A73E8'"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: { active: Boolean },
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
* {
  transition: 0.3s;
}
.iconBox {
  background-color: white;
  width: 50px;
  height: 50px;
  border: 1px solid #eaecf0;
  border-radius: 50%;
  svg {
    width: 22px;
    height: 22px;
  }
}
.iconBox.active {
  background-color: #eef5fe;
  border-color: transparent;
  width: 58.262px;
  height: 58.262px;
  svg {
    width: 30px;
    height: 30px;
  }
}
</style>
