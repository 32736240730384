<template>
  <div class="bgBox">
    <div class="conBox">
      <div class="fontBox">
        <div class="fontCon">
          <div class="conH3">{{ $fanyi("无限目录") }}</div>
          <div class="conH2">{{ $fanyi("数以百万计的认证产品") }}</div>
          <div class="conCon">
            {{
              $fanyi(
                "找到你的下一个商业机会！通过我们与1688.com的联盟，访问世界上最大的制造商目录。和一切，从你的处置！"
              )
            }}
          </div>
        </div>
      </div>
      <div class="goodsList flex flw jcenter">
        <div
          class="SearchConterCrosswiseCon"
          v-for="(item, index) in lists"
          :key="index"
          @click="
            isLogin
              ? $fun.routerToPage(
                  '/ProductDetails?goods_id=' + item.goods_id,
                  true
                )
              : $store.commit('getshowLoginDialog', true)
          "
        >
          <!-- 放图片的div -->
          <div class="SearchConterCrosswiseImg">
            <!-- <img class="goodsImg" :src="item.imgUrl" /> -->
            <el-image :src="item.imgUrl" class="goodsImg">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
              <div slot="placeholder">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
            <!-- <loadingImg :src="item.imgUrl" /> -->
          </div>
          <!-- 显示其他信息的div -->
          <div class="rests">
            <p class="goodsTitle" :title="item.title">{{ item.title }}</p>
            <p class="flex acenter">
              <span
                >{{
                  $fun.EURNumSegmentation(
                    $fun.ceil(item.goodsPrice * $store.state.exchangeRate)
                  )
                }}€</span
              >{{ $fun.RMBNumSegmentation(item.goodsPrice) }}￥
            </p>
            <div class="totalTagBox" v-if="false">
              Ventas mensuales: {{ item.monthSold }} artículos
            </div>
            <div class="totalTagBox" v-if="false">
              Tasa de recompra: {{ item.repurchaseRate }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["show"],
  data() {
    return {
      lists: [],
    };
  },
  components: {},
  computed: {
    isLogin() {
      return !this.show;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$api.getHomeGoodsList().then((res) => {
        ////console.log('事件名',res)
        if (res.code != 0)
          return this.$message.error(this.$fanyi(res.data.msg));
        //接下来的操作
        // 把评分的数据转化为数值型;
        let shop_id = [];
        this.lists = [];

        this.lists = res.data.result;
      });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.bgBox {
  padding: 56px 0;
}
.conBox {
  max-width: 1440px;
  margin: 0 auto;

  .fontBox {
    text-align: center;
    margin: 0 auto 72px;
    width: 820px;
    .conH3 {
      color: var(--Rakumart-500, #1a73e8);
      font-size: 20px;
      font-weight: 700;
      line-height: 30px; /* 150% */
      margin-bottom: 8px;
    }
    .conH2 {
      color: var(--Gray-950, #0c111d);

      font-size: 36px;
      font-weight: 600;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
      margin-bottom: 16px;
    }
    .conCon {
      font-size: 18px;
      line-height: 28px; /* 155.556% */
    }
  }

  .goodsList {
    gap: 24px;
    .SearchConterCrosswiseCon {
      position: relative;
      transition: 0.3s;
      background-color: white;
      width: 256px;
      height: 340px;
      border: solid 1px #e8e8e8;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);

      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        transform: translate(0, -5px);
        box-shadow: 0px 10px 20px #d1d1d1;

        .similarProducts {
          opacity: 1;
        }
      }

      &:nth-child(5n) {
        margin-right: 0px;
      }

      .SearchConterCrosswiseImg {
        height: 256px;

        overflow: hidden;
        position: relative;

        > .goodsImg {
          background-color: transparent;
        }

        .el-image {
          transition: 0.3s;
          width: 260px;
          height: 260px;
        }
      }

      .rests {
        box-sizing: border-box;
        padding: 16px;

        p {
          font-size: 12px;

          font-weight: 400;
          color: #000000;

          &.goodsTitle {
            @extend .text-overflow-one;
            height: 23px;
            font-size: 16px;
            font-weight: 600;
            color: var(--Gray-950, #0c111d);
          }

          &:nth-child(2) {
            font-size: 18px;
            font-weight: 600;
            color: #667085;
            > span {
              line-height: 28px;
              color: #1a73e8;
              font-weight: 700;
              margin-right: 8px;
            }
          }

          .collect {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }

            span {
              font-size: 14px;

              color: #888888;
              line-height: 20px;
            }
          }
        }
        .totalTagBox {
          @extend .text-overflow-one;
          border-radius: 6px;
          border: 1px solid var(--Blue-200, #b2ddff);
          background: var(--Blue-50, #eff8ff);
          font-size: 13px;
          color: #1a73e8;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 8px;
          padding-left: 4px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
</style>
