<!-- 网站优势 -->
<template>
  <div class="dip bgBox">
    <div class="conBox">
      <div class="keyAdvantagesBox">
        <div class="keyAdvantagesItem">
          <div class="headIconBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670e1521bfbfb.svg"
              alt=""
            />
          </div>
          <div class="keyAdvantagesItemH2">
            {{ $fanyi("具有经过验证的供应商的无限目录") }}
          </div>
          <div class="keyAdvantagesItemCon">
            {{ $fanyi("进入世界上最大的批发市场，发现它提供的所有机会。") }}
          </div>
        </div>

        <div class="keyAdvantagesItem">
          <div class="headIconBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670e1524dd6ad.svg"
              alt=""
            />
          </div>
          <div class="keyAdvantagesItemH2">
            {{ $fanyi("与中国直接联系") }}
          </div>
          <div class="keyAdvantagesItemCon">
            {{
              $fanyi(
                "在这个过程的每个阶段控制你的货物。我们在中国和西班牙的基础设施使进口的实时跟踪成为可能。"
              )
            }}
          </div>
        </div>

        <div class="keyAdvantagesItem">
          <div class="headIconBox">
            <img
              src=" https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670e15275f562.svg"
              alt=""
            />
          </div>
          <div class="keyAdvantagesItemH2">
            {{ $fanyi("个性化客户服务") }}
          </div>
          <div class="keyAdvantagesItemCon">
            {{
              $fanyi(
                "我们的专家团队将用西班牙语回答您的所有问题，并指导您完成进口的每一步。"
              )
            }}
          </div>
        </div>

        <div class="keyAdvantagesItem">
          <div class="headIconBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670e1529c3ffb.svg"
              alt=""
            />
          </div>
          <div class="keyAdvantagesItemH2">
            {{ $fanyi("定制的商业体验") }}
          </div>
          <div class="keyAdvantagesItemCon">
            {{
              $fanyi(
                "定制您从中国进口的产品。选择您需要的产品，并根据您的具体需求定制服务。"
              )
            }}
          </div>
        </div>
      </div>
      <div class="weMadeBox">
        <span style="color: #1A73E8;">Juntos</span> creamos una experiencia
        única
      </div>

      <div class="flll33 cooperationBox">
        <div class="mask"></div>
        <div class="swiper-wrapper">
          <div class="swiper-slide dip" v-for="(it, oin) in imgList" :key="oin">
            <img :src="it" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  data() {
    return {
      imgList: [
        "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/671708ba2641a.jpg",
        "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/6717086ab4dfa.png",
        "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/6716193a610b9.svg",
        "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/6716194b4c6b7.svg",
      ],
    };
  },
  components: {},
  computed: {},
  created() {},
  mounted() {
    this.getSwiper();
  },
  methods: {
    getSwiper() {
      this.swiper = new Swiper(".flll33", {
        loop: true, // 无缝
        autoplay: {
          // 自动开始
          delay: 3000, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        },
        slidesPerView: "auto",
        // slidesPerView: "4", 一组多少个
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 30, // 间隔
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
      });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.bgBox {
  background: #f9fafb;
}
.conBox {
  padding: 56px 0;

  .keyAdvantagesBox {
    @extend .dip;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 56px;
    .keyAdvantagesItem {
      padding: 32px 30px;
      width: 374px;
      min-height: 368px;
      border-radius: 16px;
      border: 2px solid var(--Gray-200, #eaecf0);
      background: #fff;

      .headIconBox {
        img {
          width: 56px;
        }
        margin-bottom: 16px;
      }

      .keyAdvantagesItemH2 {
        color: var(--Gray-950, #0c111d);
        font-size: 30px;
        font-weight: 700;
        line-height: 38px; /* 126.667% */
        margin-bottom: 16px;
      }

      .keyAdvantagesItemCon {
        font-size: 18px;
        line-height: 28px; /* 155.556% */
      }
    }
  }

  .weMadeBox {
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    text-align: center;
    margin-bottom: 25px;
    letter-spacing: -0.72px;
  }

  .cooperationBox {
    width: 1600px;
    position: relative;
    pointer-events: none;
    overflow: hidden;
    background-color: #f8faf9;
    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        90deg,
        rgba(#f8faf9, 0.9) 0%,
        rgba(#f8faf9, 0.3) 40%,
        rgba(#f8faf9, 0.3) 50%,
        rgba(#f8faf9, 0.9) /* 这里不要有, */
      );
      z-index: 2;
    }
    .swiper-slide {
      width: min-content !important;
      height: 96px;
      margin-right: 96px !important;
    }
  }
}
</style>
