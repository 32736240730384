<!-- 注册邀请-如何运作 -->
<template>
  <div class=" bgBox ">
    <div class="conBox ">
      <div class="fontBox">
        <div class="fontCon">
          <div class="conH3">{{ $fanyi("优化产品搜索") }}</div>
          <div class="conH2">{{ $fanyi("简化流程，找到你想要的东西") }}</div>
          <div class="conCon">
            {{
              $fanyi(
                "通过我们的图片或链接搜索1688，节省您的时间专注于您的业务。你是我们的首要任务。"
              )
            }}
          </div>
        </div>
      </div>

      <div class="operationBox flex jcenter acenter">
        <div class="operationListBox">
          <div
            class="operationItemBox"
            :class="{ active: active == 0 }"
            @mouseenter="active = 0"
          >
            <div class="outIconBox"><icon1 :active="active == 0" /></div>

            <div class="operationItemFontBox">
              <label>{{ $fanyi("图像搜索") }}</label>

              <span>{{
                $fanyi(
                  "在Rakumart中，您可以通过图像搜索产品，优化您的搜索，以找到您想要进口的产品。"
                )
              }}</span>
            </div>
          </div>

          <div
            class="operationItemBox"
            :class="{ active: active == 1 }"
            @mouseenter="active = 1"
          >
            <div class="outIconBox"><icon2 :active="active == 1" /></div>

            <div class="operationItemFontBox">
              <label>{{ $fanyi("开始新订单") }}</label>
              <span>{{
                $fanyi(
                  "找到您想要导入的产品，对其进行定制，并添加其他服务。然后得到一个报价，包括从中国的运费。"
                )
              }}</span>
            </div>
          </div>

          <div
            class="operationItemBox"
            :class="{ active: active == 2 }"
            @mouseenter="active = 2"
          >
            <div class="outIconBox"><icon3 :active="active == 2" /></div>

            <div class="operationItemFontBox">
              <label>{{ $fanyi("定制你的产品") }}</label>
              <span>{{
                $fanyi(
                  "当您开始订购时，个性化您的产品并添加额外的服务。你有一个标志吗？自定义图像，使您的产品独特。"
                )
              }}</span>
            </div>
          </div>

          <div
            class="operationItemBox"
            :class="{ active: active == 3 }"
            @mouseenter="active = 3"
          >
            <div class="outIconBox"><icon4 :active="active == 3" /></div>

            <div class="operationItemFontBox">
              <label>{{ $fanyi("货物的报价和付款") }}</label>
              <span>{{
                $fanyi(
                  "我们将在48小时内报价您的订单和运输费用。Rakumart确保安全可靠的支付，保护您的数据在每一笔交易。"
                )
              }}</span>
            </div>
          </div>

          <div
            class="operationItemBox"
            :class="{ active: active == 4 }"
            @mouseenter="active = 4"
          >
            <div class="outIconBox"><icon5 :active="active == 4" /></div>

            <div class="operationItemFontBox">
              <label>{{ $fanyi("物流、运输和接收订单") }}</label>
              <span>{{
                $fanyi(
                  "付款后，我们将按照您选择的运输方式发送您的订单。Rakumart控制和跟踪您的货物从中国直到收到。"
                )
              }}</span>
            </div>
          </div>
        </div>
        <el-carousel
          ref="imgBoxRef"
          height="550px"
          direction="vertical"
          indicator-position="none"
          :autoplay="false"
          class="imgBox"
        >
          <el-carousel-item
            v-for="(item, index) in imgList"
            :key="index"
            class="acenter flex"
          >
            <img
              class="itemImg"
              :src="item"
              alt=""
              :class="{ img1: index == 0 }"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
import icon1 from "./assets/icon1.vue";
import icon2 from "./assets/icon2.vue";
import icon3 from "./assets/icon3.vue";
import icon4 from "./assets/icon4.vue";
import icon5 from "./assets/icon5.vue";
export default {
  data() {
    return {
      active: 0,
      imgList: [
        "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/671760b89d872.png",
        "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f315a929c7.png",
        "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f31644e16c.png",
        "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f315f902d4.png",
        "https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f3168320ad.png",
      ],
    };
  },
  components: {
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
  },
  watch: {
    active(newVal, oldVal) {
      console.log(this.active, 222);
      this.$refs.imgBoxRef.setActiveItem(this.active);
    },
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
* {
  // border: solid;
}
.bgBox {
  background: #fff;
}
.conBox {
  padding: 56px 0;
  .fontBox {
    text-align: center;
    margin: 0 auto 15px;
    width: 820px;
    .conH3 {
      color: var(--Rakumart-500, #1a73e8);
      font-size: 20px;
      font-weight: 700;
      line-height: 30px; /* 150% */
      margin-bottom: 8px;
    }
    .conH2 {
      color: var(--Gray-950, #0c111d);

      font-size: 36px;
      font-weight: 600;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
      margin-bottom: 16px;
    }
    .conCon {
      font-size: 18px;
      line-height: 24px; /* 150% */
    }
  }

  .operationBox {
    .operationListBox {
      width: 665px;
      .operationItemBox {
        @extend .dip;

        min-height: 50px;
        transition: 0.3s;
        margin-bottom: 35px;
        &:last-child {
          margin-bottom: 0;
        }
        * {
          transition: 0.3s;
        }
        .outIconBox {
          margin-right: 21.47px;
        }
        .operationItemFontBox {
          display: flex;
          flex: 1;
          flex-direction: column;
          label {
            color: var(--Gray-950, #0c111d);
            font-size: 24px;
            font-weight: 600;
            line-height: 32px; /* 133.333% */
          }
          span {
            font-size: 18px;
            line-height: 28px; /* 155.556% */
            display: block;
            overflow: hidden;
            max-height: 0;
          }
        }
      }

      .operationItemBox.active {
        min-height: 104px;
        align-items: flex-start;
        .operationItemFontBox {
          label {
            margin-bottom: 16px;
          }
          span {
            max-height: 84px;
          }
        }
      }
    }

    .imgBox {
      margin-left: 80px;
      flex: 0 0 700px;
      text-align: right;
      .itemImg {
        // width: 616px;
        // height: 500px;
        background-position: center;
      }
      .itemImg.img1 {
        // width: 785px;
        // height: auto;
        // transform: translateY(-47px);
      }
    }
  }

  .bgBlueBtn {
    width: 320px;
    height: 60px;
    font-size: 18px;
    font-weight: 700;
  }
}
</style>
