<!-- 折叠面板 -->
<template>
  <div class="foldingPanelBox">
    <div
      @click="
        titleClick();
        openColp();
      "
      class="titleBox"
    >
      <div class="titleConBox">
        <slot name="title"></slot>
      </div>
      <div class="iconBox" :class="{ opIc: show }">
        <img
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6683797671ecb.svg"
          alt=""
        />
      </div>
    </div>

    <transition
      :name="transitionName"
      @before-enter="collapseBeforeEnter"
      @enter="collapseEnter"
      @after-enter="collapseAfterEnter"
      @before-leave="collapseBeforeLeave"
      @leave="collapseLeave"
      @after-leave="collapseAfterLeave"
    >
      <div v-show="show" class="conBox">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * 元素折叠过度效果
 */
export default {
  name: "CollapseTransition",
  props: {
    transitionName: {
      type: String,
      default: "collapse-transition",
    },
    // 自身的ref名字(多个使用时必传)
    oref: {
      type: String,
      default: "",
    },
    // 需要关闭的组件的ref(多个使用时必传)
    refList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["titleClock"],
  data() {
    return {
      oldPaddingTop: "",
      oldPaddingBottom: "",
      oldOverflow: "",
      show: false,
    };
  },
  methods: {
    // 关闭需要关闭的下拉面板并打开这个下拉面板
    openColp() {
      for (let i = 0; i < this.refList.length; i++) {
        if (
          this.$parent.$refs[this.refList[i]] &&
          this.refList[i] != this.oref
        ) {
          this.$parent.$refs[this.refList[i]].show = false;
        }
      }
      this.show = !this.show;
    },
    titleClick() {
      this.$emit("titleClock");
    },
    collapseBeforeEnter(el) {
      // console.log('11, collapseBeforeEnter');
      this.oldPaddingBottom = el.style.paddingBottom;
      this.oldPaddingTop = el.style.paddingTop;
      // 过渡效果开始前设置元素的maxHeight为0，让元素maxHeight有一个初始值
      el.style.paddingTop = "0";
      el.style.paddingBottom = "0";
      el.style.maxHeight = "0";
    },
    collapseEnter(el, done) {
      // console.log('22, collapseEnter');
      //
      this.oldOverflow = el.style.overflow;
      let elHeight = el.scrollHeight;
      // 过渡效果进入后将元素的maxHeight设置为元素本身的高度，将元素maxHeight设置为auto不会有过渡效果
      if (elHeight > 0) {
        el.style.maxHeight = elHeight + "px";
      } else {
        el.style.maxHeight = "0";
      }
      el.style.paddingTop = this.oldPaddingTop;
      el.style.paddingBottom = this.oldPaddingBottom;

      el.style.overflow = "hidden";
      // done();
      let onTransitionDone = function() {
        done();
        // console.log('enter onTransitionDone');
        el.removeEventListener("transitionend", onTransitionDone, false);
        el.removeEventListener("transitioncancel", onTransitionDone, false);
      };
      // 绑定元素的transition完成事件，在transition完成后立即完成vue的过度动效
      el.addEventListener("transitionend", onTransitionDone, false);
      el.addEventListener("transitioncancel", onTransitionDone, false);
    },
    collapseAfterEnter(el) {
      // console.log('33, collapseAfterEnter');
      // 过渡效果完成后恢复元素的maxHeight
      el.style.maxHeight = "";
      el.style.overflow = this.oldOverflow;
    },

    collapseBeforeLeave(el) {
      // console.log('44, collapseBeforeLeave', el.scrollHeight);
      this.oldPaddingBottom = el.style.paddingBottom;
      this.oldPaddingTop = el.style.paddingTop;
      this.oldOverflow = el.style.overflow;

      el.style.maxHeight = el.scrollHeight + "px";
      el.style.overflow = "hidden";
    },
    collapseLeave(el, done) {
      // console.log('55, collapseLeave', el.scrollHeight);

      if (el.scrollHeight !== 0) {
        el.style.maxHeight = "0";
        el.style.paddingBottom = "0";
        el.style.paddingTop = "0";
      }
      // done();
      let onTransitionDone = function() {
        done();
        // console.log('leave onTransitionDone');
        el.removeEventListener("transitionend", onTransitionDone, false);
        el.removeEventListener("transitioncancel", onTransitionDone, false);
      };
      // 绑定元素的transition完成事件，在transition完成后立即完成vue的过度动效
      el.addEventListener("transitionend", onTransitionDone, false);
      el.addEventListener("transitioncancel", onTransitionDone, false);
    },
    collapseAfterLeave(el) {
      // console.log('66, collapseAfterLeave');
      el.style.maxHeight = "";
      el.style.overflow = this.oldOverflow;
      el.style.paddingBottom = this.oldPaddingBottom;
      el.style.paddingTop = this.oldPaddingTop;

      this.oldOverflow = this.oldPaddingBottom = this.oldPaddingTop = "";
    },
  },
};
</script>

<style lang="scss">
.foldingPanelBox {
  background: #ffffff;
  border-radius: 16px;
  border: 2px solid var(--Gray-200, #eaecf0);
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  .titleBox {
    height: 76px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .titleConBox {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: var(--Gray-700, #344054);

      font-size: 18px;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
    }

    .iconBox {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
        rotate: 180deg;
        transition: 0.3s;
      }
    }
    .opIc {
      img {
        rotate: 0deg;
      }
    }
  }
  .conBox {
    transform: translateY(-5px);
    padding: 0 24px 16px;
  }
}
.collapse-transition-enter-active,
.collapse-transition-leave-active {
  transition: height 0.3s ease-in-out, padding 0.3s ease-in-out,
    max-height 0.3s ease-in-out;
}
</style>
