var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bgBox"},[_c('div',{staticClass:"conBox",class:{ loginTop: _vm.isLogin }},[_c('div',{staticClass:"fontBox"},[_c('div',{staticClass:"conH3"},[_vm._v(_vm._s(_vm.$fanyi("质量和信心")))]),_c('div',{staticClass:"conH2"},[_vm._v(_vm._s(_vm.$fanyi("已验证的供应商数千")))]),_c('div',{staticClass:"conCon"},[_vm._v(" "+_vm._s(_vm.$fanyi("专家团队可让您接触到市场上最好的供应商"))+" ")])]),_c('div',{staticClass:"shopList flex jcenter flw"},_vm._l((_vm.lists),function(shopItem,shopIndex){return _c('div',{key:shopIndex,staticClass:"shopItem"},[_c('div',{staticClass:"shopName flex acenter"},[_c('span',{on:{"click":function($event){_vm.isLogin
                ? _vm.$fun.routerToPage(
                    ("/shopGoods?shopName=" + (shopItem.shop_name_translate) + "&shopId=" + (shopItem.shop_id)),
                    true
                  )
                : _vm.$store.commit('getshowLoginDialog', true)}}},[_vm._v(_vm._s(shopItem.shop_name_translate))]),(shopItem.shopFavoriteStatus)?_c('button',{staticClass:"fontBlueBtn favoShopBtn",on:{"click":function($event){return _vm.favoriteShopDelete(shopItem)}}},[_vm._v(" "+_vm._s(_vm.$fanyi("取消追踪"))+" ")]):_c('button',{staticClass:"bgBlueBtn favoShopBtn",on:{"click":function($event){return _vm.shopAddFavorite(shopItem)}}},[_c('img',{attrs:{"src":"https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d2c021ac9a1.svg","alt":""}}),_vm._v(_vm._s(_vm.$fanyi("收藏(简短)"))+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"rateBox"},[_c('div',{staticClass:"rateContainerHead flex acenter"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$fanyi("品质保证")))]),_c('el-popover',{attrs:{"popper-class":"rateBoxPopover","placement":"bottom","width":"309","trigger":"hover"},on:{"show":function($event){shopItem.hoverTip = true;
                  _vm.lists.splice(0, 0);},"hide":function($event){shopItem.hoverTip = false;
                  _vm.lists.splice(0, 0);}}},[_c('div',{staticClass:"popoverRateBox"},[_c('div',{staticClass:"rateContainer"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$fanyi("质量评分")))]),_c('el-rate',{attrs:{"disabled":"","text-color":"#ff9900"},model:{value:(shopItem.sellerDataInfo.offerExperienceScore),callback:function ($$v) {_vm.$set(shopItem.sellerDataInfo, "offerExperienceScore", $$v)},expression:"shopItem.sellerDataInfo.offerExperienceScore"}})],1),_c('div',{staticClass:"rateContainer"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$fanyi("物流评分")))]),_c('el-rate',{attrs:{"disabled":"","text-color":"#ff9900"},model:{value:(
                        shopItem.sellerDataInfo.logisticsExperienceScore
                      ),callback:function ($$v) {_vm.$set(shopItem.sellerDataInfo, "logisticsExperienceScore", $$v)},expression:"\n                        shopItem.sellerDataInfo.logisticsExperienceScore\n                      "}})],1),_c('div',{staticClass:"rateContainer"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$fanyi("问题解决评分")))]),_c('el-rate',{attrs:{"disabled":"","text-color":"#ff9900"},model:{value:(shopItem.sellerDataInfo.disputeComplaintScore),callback:function ($$v) {_vm.$set(shopItem.sellerDataInfo, "disputeComplaintScore", $$v)},expression:"shopItem.sellerDataInfo.disputeComplaintScore"}})],1),_c('div',{staticClass:"rateContainer"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$fanyi("咨询评分")))]),_c('el-rate',{attrs:{"disabled":"","text-color":"#ff9900"},model:{value:(
                        shopItem.sellerDataInfo.consultingExperienceScore
                      ),callback:function ($$v) {_vm.$set(shopItem.sellerDataInfo, "consultingExperienceScore", $$v)},expression:"\n                        shopItem.sellerDataInfo.consultingExperienceScore\n                      "}})],1)]),_c('div',{staticClass:"flex acenter",attrs:{"slot":"reference"},slot:"reference"},[_c('el-rate',{attrs:{"disabled":"","text-color":"#ff9900"},model:{value:(shopItem.sellerDataInfo.compositeServiceScore),callback:function ($$v) {_vm.$set(shopItem.sellerDataInfo, "compositeServiceScore", $$v)},expression:"shopItem.sellerDataInfo.compositeServiceScore"}}),_c('img',{staticClass:"rateIcon",class:{ hoverIn: shopItem.hoverTip },attrs:{"src":"https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6683797671ecb.svg","alt":""}})],1)])],1)]),_c('div',{staticClass:"sellerTagsBar flex acenter"},[(_vm.haveShopsTag('powerful_merchants', shopItem))?_c('div',{staticClass:"iconBox blue"},[_c('img',{attrs:{"src":require('@/assets/icon/goods1.svg'),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$fanyi("实力商家")))])]):_vm._e(),(_vm.haveShopsTag('super_factory', shopItem))?_c('div',{staticClass:"iconBox red"},[_c('img',{attrs:{"src":require('@/assets/icon/goods2.svg'),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$fanyi("超级工厂")))])]):_vm._e()])]),_c('div',{staticClass:"topGoodsList flex jsb"},_vm._l((shopItem.shopGoodTop),function(goodsItem,goodsIndex){return _c('div',{key:goodsIndex,staticClass:"shopTopGoodsItem",on:{"click":function($event){_vm.isLogin
                ? _vm.$fun.routerToPage(
                    '/ProductDetails?goods_id=' + goodsItem.goodsId,
                    true
                  )
                : _vm.$store.commit('getshowLoginDialog', true)}}},[_c('div',{staticClass:"topGoodsImg"},[_c('img',{attrs:{"src":goodsItem.imgUrl,"alt":""}})]),_c('div',{staticClass:"topTip"},[_vm._v("TOP "+_vm._s(goodsIndex + 1))])])}),0)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }