<template>
  <div :class="{ active: active }" class="iconBox dip">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 10.5L11 12.5L15.5 8M19 21V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H9.8C8.11984 3 7.27976 3 6.63803 3.32698C6.07354 3.6146 5.6146 4.07354 5.32698 4.63803C5 5.27976 5 6.11984 5 7.8V21L12 17L19 21Z"
        :stroke="!active ? '#344054' : '#1A73E8'"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: { active: Boolean },
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
* {
  transition: 0.3s;
}
.iconBox {
  background-color: white;
  width: 50px;
  height: 50px;
  border: 1px solid #eaecf0;
  border-radius: 50%;
  svg {
    width: 22px;
    height: 22px;
  }
}
.iconBox.active {
  background-color: #eef5fe;
  border-color: transparent;
  width: 58.262px;
  height: 58.262px;
  svg {
    width: 30px;
    height: 30px;
  }
}
</style>
