import { render, staticRenderFns } from "./howToOperate.vue?vue&type=template&id=14200b24&scoped=true&"
import script from "./howToOperate.vue?vue&type=script&lang=js&"
export * from "./howToOperate.vue?vue&type=script&lang=js&"
import style0 from "./howToOperate.vue?vue&type=style&index=0&id=14200b24&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14200b24",
  null
  
)

export default component.exports