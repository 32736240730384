<!-- 进口好处 -->
<template>
  <div class=" bgBox dip">
    <div class="conBox ">
      <div class="fontBox">
        <div class="fontCon">
          <div class="conH3">{{ $fanyi("从中国，不离开你的办公室") }}</div>
          <div class="conH2">{{ $fanyi("与中国的贸易民主化") }}</div>
          <div class="conCon">
            {{ $fanyi("从中国进口的好处，但没有它的复杂性。") }}
          </div>
        </div>
      </div>
      <div class="itemRowListBox">
        <div class="rowBox dip">
          <!-- 图片 -->
          <div class="imgBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/6717150aea0cf.png"
              alt=""
            />
          </div>
          <!-- 文字 -->
          <div>
            <div class="rowfontBox">
              <div class="titleH2">{{ $fanyi("找到你想要的一切") }}</div>
              <div class="titleH3">
                {{
                  $fanyi(
                    "在Rakumart，你可以找到数以百万计的产品，从亚洲巨人最好的供应商的手，最好的质量和价格保证！"
                  )
                }}
              </div>
              <div class="rowUl">
                <div class="rowLi">
                  <img
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/671716e72e7dd.svg"
                    alt=""
                  />
                  <span><b>+100.000 proveedores</b> verificados.</span>
                </div>

                <div class="rowLi">
                  <img
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/671716e72e7dd.svg"
                    alt=""
                  />
                  <span><b>Millones de productos</b> disponibles.</span>
                </div>
                <div class="rowLi">
                  <img
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/671716e72e7dd.svg"
                    alt=""
                  />
                  <span
                    ><b>Partner</b> oficial de
                    <b style="color: #DC6803;">1688.com</b></span
                  >
                </div>
              </div>
            </div>
            <el-button
              type="primary"
              class="bgBlueBtn"
              @click="$fun.routerToPage('/register')"
              >{{ $fanyi("免费注册") }}</el-button
            >
          </div>
        </div>

        <div class="rowBox dip" style="height: 545px;">
          <!-- 文字 -->
          <div>
            <div class="rowfontBox">
              <div class="titleH2">{{ $fanyi("与中国直接联系") }}</div>
              <div class="titleH3">
                {{
                  $fanyi(
                    "没有更多的头痛！我们负责从工厂到您家门口的整个物流过程。把时间花在真正重要的事情上，你的业务。"
                  )
                }}
              </div>
              <div class="rowUl">
                <div class="rowLi">
                  <img
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/671716e72e7dd.svg"
                    alt=""
                  />
                  <span
                    ><b>Nos encargamos de toda la gestión</b> documentaria,
                    trámites y procesos aduaneros.</span
                  >
                </div>
                <div class="rowLi">
                  <img
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/671716e72e7dd.svg"
                    alt=""
                  />
                  <span
                    >Contamos con <b>infraestructura logística</b> tanto
                    <b>en China como en España</b>, esto nos permite tener bajo
                    control tú mercancía durante todo el proceso de
                    importación.</span
                  >
                </div>
                <div class="rowLi">
                  <img
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/671716e72e7dd.svg"
                    alt=""
                  />
                  <span>Todo el proceso <b>100% en español</b>.</span>
                </div>
              </div>
            </div>
            <el-button
              type="primary"
              class="bgBlueBtn"
              @click="$fun.routerToPage('/register')"
              >{{ $fanyi("免费注册") }}</el-button
            >
          </div>
          <!-- 图片 -->
          <div class="imgBox">
            <img
              style="width: 885px;"
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/671f51e3db881.png"
              alt=""
            />
          </div>
        </div>

        <div class="rowBox dip">
          <!-- 图片 -->
          <div class="imgBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/67171d8e74722.png"
              alt=""
            />
          </div>
          <!-- 文字 -->
          <div>
            <div class="rowfontBox" style="width: 530px;padding-right: 40px;">
              <div class="titleImg dip jstart">
                <img
                  src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/6717208c22ad8.png
"
                  alt=""
                />
              </div>
              <div class="titleH2">{{ $fanyi("单一平台上的全部流程") }}</div>
              <div class="titleH3">
                {{
                  $fanyi(
                    "从一个地方管理你的商品。所有在一起！无需离开我们的平台即可享受导入过程。"
                  )
                }}
              </div>
              <div class="rowUl">
                <div class="rowLi">
                  <img
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/671716e72e7dd.svg"
                    alt=""
                  />
                  <span>Control a <b>tiempo real</b> de tu mercancía. </span>
                </div>
                <div class="rowLi">
                  <img
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/671716e72e7dd.svg"
                    alt=""
                  />
                  <span
                    >Crea <b>colecciones de proveedores y productos</b> para
                    tenerlos siempre a mano.</span
                  >
                </div>
                <div class="rowLi">
                  <img
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/671716e72e7dd.svg"
                    alt=""
                  />
                  <span
                    >Sé parte de la <b>comunidad</b> más grande
                    <b>de importadores</b>.
                  </span>
                </div>
              </div>
            </div>
            <el-button
              type="primary"
              class="bgBlueBtn"
              @click="$fun.routerToPage('/register')"
              >{{ $fanyi("免费注册") }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  watch: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.bgBox {
  background: #fff;
}
.conBox {
  padding: 56px 0;
  .fontBox {
    text-align: center;
    margin: 0 auto 80px;
    .conH3 {
      color: var(--Rakumart-500, #1a73e8);
      font-size: 20px;
      font-weight: 700;
      line-height: 30px; /* 150% */
      margin-bottom: 8px;
    }
    .conH2 {
      color: var(--Gray-950, #0c111d);

      font-size: 36px;
      font-weight: 600;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
      margin-bottom: 16px;
    }
    .conCon {
      font-size: 18px;
      line-height: 28px; /* 155.556% */
    }
  }

  .itemRowListBox {
    width: 1470px;
    @extend .fdcolmun;
    gap: 112px;
    .rowBox {
      @extend .jsb;
      gap: 96px;
      .imgBox {
        img {
          //   width: 100%;
          //   height: 100%;
        }
      }

      .rowfontBox {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .titleH2 {
          color: var(--Gray-950, #0c111d);
          font-size: 30px;
          font-weight: 700;
          line-height: 38px; /* 126.667% */
        }
        .titleH3 {
          color: var(--Gray-700, #344054);
          font-size: 18px;
          line-height: 28px; /* 155.556% */
        }
        .rowUl {
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding-left: 16px;
          .rowLi {
            display: flex;
            align-items: flex-start;
            img {
              width: 28px;
              height: 28px;
              margin-right: 12px;
            }
            span {
              font-size: 16px;
              line-height: 24px; /* 150% */
            }
          }
        }
      }
      .bgBlueBtn {
        margin-top: 48px;
        width: 183px;
        height: 44px;
      }
    }
  }
}
</style>
